import {
    CHANGE_CHANNEL_PARTNER_STATUS,
    CHANGE_CHANNEL_PARTNER_STATUS_SUCCESS,
    CREATE_CHANNEL_PARTNER,
    CREATE_CHANNEL_PARTNER_SUCCESS,
    C_P_FEEDBACK_LIST,
    C_P_FEEDBACK_LIST_SUCCESS,
    C_P_SEND_OPT,
    C_P_SEND_OPT_SUCCESS,
    C_P_SUBMIT_OPT,
    C_P_SUBMIT_OPT_SUCCESS,
    GET_BANK_DETAILS,
    GET_BANK_DETAILS_SUCCESS,
    GET_CHANNEL_PARTNER_INFO,
    GET_CHANNEL_PARTNER_INFO_SUCCESS,
    GET_CHANNEL_PARTNER_LIST,
    GET_CHANNEL_PARTNER_LIST_SUCCESS,
    GET_C_P_INVOICE_LIST,
    GET_C_P_INVOICE_LIST_SUCCESS,
    GET_INV_REF_LIST_BY_INV_ID,
    GET_INV_REF_LIST_BY_INV_ID_SUCCESS,
    GET_REFERALS_LIST,
    GET_REFERALS_LIST_SUCCESS,
    GET_REFERRAL_LINK,
    GET_REFERRAL_LINK_SUCCESS,
    GET_REF_LIST_FOR_INVOICE_SAVE,
    GET_REF_LIST_FOR_INVOICE_SAVE_SUCCESS,
    LOGIN_CHANNEL_PARTNER,
    LOGIN_CHANNEL_PARTNER_SUCCESS,
    SAVE_BANK_DETAILS,
    SAVE_BANK_DETAILS_SUCCESS,
    SAVE_C_P_FEEDBACK,
    SAVE_C_P_FEEDBACK_SUCCESS,
    SAVE_C_P_INVOICE,
    SAVE_C_P_INVOICE_SUCCESS,
    SET_REF_DATA_FROM_ADMIN,
    UPDATE_INVOICE_STATUS,
    UPDATE_INVOICE_STATUS_SUCCESS,
    UPDATE_INV_PAYMENT_STATUS,
    UPDATE_INV_PAYMENT_STATUS_SUCCESS,
    UPDATE_REFERAL_STATUS,
    UPDATE_REFERAL_STATUS_SUCCESS
} from "src/actions/actionTypes";
import { InsertFunctionStatus } from "src/util/enums";

const initialState = {
    partnerSaveStatus: InsertFunctionStatus.NONE,
    loginPartnerData: null,
    channelPartnerInfo: null,
    channelPartnerList: null,
    channelPartnerStatus: null,
    cpSendOTPResp: null,
    cpSubmitOTPResp: null,
    referalList: null,
    updateReferalStatus: null,
    refDataFromAdmin: null,
    cpInvoiceList: null,
    cpInvoiceSave: null,
    updateInvStatus: null,
    cpRefListForSave: null,
    appInvoiceList: null,
    cpFeedbackSave: null,
    feedbackList: null,
    invPayStatus: null,
    saveBankDetailsStatus: null,
    bankDetails: null,
    referralLink: null
};
export default function partnerReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_CHANNEL_PARTNER: {
            return { ...state, partnerSaveStatus: null };
        }
        case CREATE_CHANNEL_PARTNER_SUCCESS: {
            return { ...state, partnerSaveStatus: action.payload };
        }
        case LOGIN_CHANNEL_PARTNER: {
            return { ...state, loginPartnerData: null };
        }
        case LOGIN_CHANNEL_PARTNER_SUCCESS: {
            return { ...state, loginPartnerData: action.payload };
        }
        case GET_CHANNEL_PARTNER_INFO: {
            return { ...state, channelPartnerInfo: null };
        }
        case GET_CHANNEL_PARTNER_INFO_SUCCESS: {
            return { ...state, channelPartnerInfo: action.payload };
        }
        case GET_CHANNEL_PARTNER_LIST: {
            return { ...state, channelPartnerList: null };
        }
        case GET_CHANNEL_PARTNER_LIST_SUCCESS: {
            return { ...state, channelPartnerList: action.payload };
        }
        case CHANGE_CHANNEL_PARTNER_STATUS: {
            return { ...state, channelPartnerStatus: null };
        }
        case CHANGE_CHANNEL_PARTNER_STATUS_SUCCESS: {
            return { ...state, channelPartnerStatus: action.payload };
        }
        case C_P_SEND_OPT: {
            return { ...state, cpSendOTPResp: action.payload };
        }
        case C_P_SEND_OPT_SUCCESS: {
            return { ...state, cpSendOTPResp: action.payload };
        }
        case C_P_SUBMIT_OPT: {
            return { ...state, cpSubmitOTPResp: action.payload };
        }
        case C_P_SUBMIT_OPT_SUCCESS: {
            return { ...state, cpSubmitOTPResp: action.payload };
        }
        case GET_REFERALS_LIST: {
            return { ...state, referalList: null };
        }
        case GET_REFERALS_LIST_SUCCESS: {
            return { ...state, referalList: action.payload };
        }
        case UPDATE_REFERAL_STATUS: {
            return { ...state, updateReferalStatus: null };
        }
        case UPDATE_REFERAL_STATUS_SUCCESS: {
            return { ...state, updateReferalStatus: action.payload };
        }
        case SET_REF_DATA_FROM_ADMIN: {
            return { ...state, refDataFromAdmin: action.payload };
        }
        case GET_C_P_INVOICE_LIST: {
            return { ...state, cpInvoiceList: null };
        }
        case GET_C_P_INVOICE_LIST_SUCCESS: {
            return { ...state, cpInvoiceList: action.payload };
        }
        case SAVE_C_P_INVOICE: {
            return { ...state, cpInvoiceSave: null };
        }
        case SAVE_C_P_INVOICE_SUCCESS: {
            return { ...state, cpInvoiceSave: action.payload };
        }
        case UPDATE_INVOICE_STATUS: {
            return { ...state, updateInvStatus: null };
        }
        case UPDATE_INVOICE_STATUS_SUCCESS: {
            return { ...state, updateInvStatus: action.payload };
        }
        case GET_REF_LIST_FOR_INVOICE_SAVE: {
            return { ...state, cpRefListForSave: null };
        }
        case GET_REF_LIST_FOR_INVOICE_SAVE_SUCCESS: {
            return { ...state, cpRefListForSave: action.payload };
        }
        case GET_INV_REF_LIST_BY_INV_ID: {
            return { ...state, appInvoiceList: null };
        }
        case GET_INV_REF_LIST_BY_INV_ID_SUCCESS: {
            return { ...state, appInvoiceList: action.payload };
        }
        case SAVE_C_P_FEEDBACK: {
            return { ...state, cpFeedbackSave: null };
        }
        case SAVE_C_P_FEEDBACK_SUCCESS: {
            return { ...state, cpFeedbackSave: action.payload };
        }
        case C_P_FEEDBACK_LIST: {
            return { ...state, feedbackList: null };
        }
        case C_P_FEEDBACK_LIST_SUCCESS: {
            return { ...state, feedbackList: action.payload };
        }
        case UPDATE_INV_PAYMENT_STATUS: {
            return { ...state, invPayStatus: null };
        }
        case UPDATE_INV_PAYMENT_STATUS_SUCCESS: {
            return { ...state, invPayStatus: action.payload };
        }
        case SAVE_BANK_DETAILS: {
            return { ...state, saveBankDetailsStatus: null };
        }
        case SAVE_BANK_DETAILS_SUCCESS: {
            return { ...state, saveBankDetailsStatus: action.payload };
        }
        case GET_BANK_DETAILS: {
            return { ...state, bankDetails: null };
        }
        case GET_BANK_DETAILS_SUCCESS: {
            return { ...state, bankDetails: action.payload };
        }
        case GET_REFERRAL_LINK: {
            return { ...state, referralLink: null };
        }
        case GET_REFERRAL_LINK_SUCCESS: {
            return { ...state, referralLink: action.payload };
        }

        default:
            return state;
    }
}
