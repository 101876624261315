import Axios from "axios";
import { pushNotificationAxios, tallyPartnerAxios, tallyStaticRepAxios } from "src/config/axios.config";
import {
    CREATE_CHANNEL_PARTNER_SUCCESS,
    CREATE_CHANNEL_PARTNER,
    CREATE_CHANNEL_PARTNER_FAILURE,
    LOGIN_CHANNEL_PARTNER,
    LOGIN_CHANNEL_PARTNER_SUCCESS,
    LOGIN_CHANNEL_PARTNER_FAILURE,
    GET_CHANNEL_PARTNER_INFO,
    GET_CHANNEL_PARTNER_INFO_SUCCESS,
    GET_CHANNEL_PARTNER_INFO_FAILURE,
    GET_CHANNEL_PARTNER_LIST,
    GET_CHANNEL_PARTNER_LIST_SUCCESS,
    GET_CHANNEL_PARTNER_LIST_FAILURE,
    CHANGE_CHANNEL_PARTNER_STATUS,
    CHANGE_CHANNEL_PARTNER_STATUS_SUCCESS,
    CHANGE_CHANNEL_PARTNER_STATUS_FAILURE,
    C_P_SEND_OPT,
    C_P_SEND_OPT_SUCCESS,
    C_P_SEND_OPT_FAILURE,
    C_P_SUBMIT_OPT,
    C_P_SUBMIT_OPT_SUCCESS,
    C_P_SUBMIT_OPT_FAILURE,
    GET_REFERALS_LIST,
    GET_REFERALS_LIST_SUCCESS,
    GET_REFERALS_LIST_FAILURE,
    GET_C_P_INVOICE_LIST,
    GET_C_P_INVOICE_LIST_SUCCESS,
    GET_C_P_INVOICE_LIST_FAILURE,
    SAVE_C_P_INVOICE,
    SAVE_C_P_INVOICE_SUCCESS,
    SAVE_C_P_INVOICE_FAILURE,
    UPDATE_INVOICE_STATUS,
    UPDATE_INVOICE_STATUS_SUCCESS,
    UPDATE_INVOICE_STATUS_FAILURE,
    UPDATE_REFERAL_STATUS,
    UPDATE_REFERAL_STATUS_SUCCESS,
    UPDATE_REFERAL_STATUS_FAILURE,
    GET_REF_LIST_FOR_INVOICE_SAVE,
    GET_REF_LIST_FOR_INVOICE_SAVE_SUCCESS,
    GET_REF_LIST_FOR_INVOICE_SAVE_FAILURE,
    UPDATE_INV_PAYMENT_STATUS,
    UPDATE_INV_PAYMENT_STATUS_SUCCESS,
    UPDATE_INV_PAYMENT_STATUS_FAILURE,
    GET_INV_REF_LIST_BY_INV_ID,
    GET_INV_REF_LIST_BY_INV_ID_SUCCESS,
    GET_INV_REF_LIST_BY_INV_ID_FAILURE,
    SAVE_C_P_FEEDBACK,
    SAVE_C_P_FEEDBACK_SUCCESS,
    SAVE_C_P_FEEDBACK_FAILURE,
    C_P_FEEDBACK_LIST,
    C_P_FEEDBACK_LIST_SUCCESS,
    C_P_FEEDBACK_LIST_FAILURE,
    GET_BANK_DETAILS,
    GET_BANK_DETAILS_SUCCESS,
    GET_BANK_DETAILS_FAILURE,
    SAVE_BANK_DETAILS,
    SAVE_BANK_DETAILS_SUCCESS,
    SAVE_BANK_DETAILS_FAILURE,
    SUBMIT_RESET_PASSWORD_DATA,
    SUBMIT_RESET_PASSWORD_DATA_SUCCESS,
    SUBMIT_RESET_PASSWORD_DATA_FAILURE,
    GET_REFERRAL_LINK_SUCCESS,
    GET_REFERRAL_LINK,
    GET_REFERRAL_LINK_FAILURE
} from "../actionTypes";



const createChannelPartner = (data) => (dispatch) => {
    dispatch({
        type: CREATE_CHANNEL_PARTNER,
    });
    return tallyPartnerAxios.post(`channel/partner/saveAndUpdateChannelPartnerDetails`, data).then(
        (res) =>
            dispatch({
                type: CREATE_CHANNEL_PARTNER_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: CREATE_CHANNEL_PARTNER_FAILURE,
                payload: err,
            })
    );
};
const partnerLogin = (email, password) => (dispatch) => {
    dispatch({
        type: LOGIN_CHANNEL_PARTNER,
    });
    return tallyPartnerAxios.get(`channel/partner/userLoginChecking?emailId=${email}&password=${password}`).then(
        (res) =>
            dispatch({
                type: LOGIN_CHANNEL_PARTNER_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: LOGIN_CHANNEL_PARTNER_FAILURE,
                payload: err,
            })
    );
};
const getChannelPartnerInfo = (uId) => (dispatch) => {
    dispatch({
        type: GET_CHANNEL_PARTNER_INFO,
    });
    return tallyPartnerAxios.get(`channel/partner/getChannelPartnerDetailById?id=${uId}`).then(
        (res) =>
            dispatch({
                type: GET_CHANNEL_PARTNER_INFO_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: GET_CHANNEL_PARTNER_INFO_FAILURE,
                payload: err,
            })
    );
};

const getChannelPartnerList = (type) => (dispatch) => {
    dispatch({
        type: GET_CHANNEL_PARTNER_LIST,
    });
    return tallyPartnerAxios.get(`channel/partner/getAllChannelPartnerDetailsByType?type=${type}`).then(
        (res) =>
            dispatch({
                type: GET_CHANNEL_PARTNER_LIST_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: GET_CHANNEL_PARTNER_LIST_FAILURE,
                payload: err,
            })
    );
};
const changeChannelPartnerStatus = (uId, status, remarks, modifiedBy, partnerType) => (dispatch) => {
    dispatch({
        type: CHANGE_CHANNEL_PARTNER_STATUS,
    });
    return tallyPartnerAxios.put(`channel/partner/updateAccountStatusOfChannelPartner?id=${uId}&status=${status}&remarks=${remarks}&modifiedBy=${modifiedBy}&partnerType=${partnerType}`, {}).then(
        (res) =>
            dispatch({
                type: CHANGE_CHANNEL_PARTNER_STATUS_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: CHANGE_CHANNEL_PARTNER_STATUS_FAILURE,
                payload: err,
            })
    );
};

const sendOTP = (type, value) => (dispatch) => {
    dispatch({
        type: C_P_SEND_OPT,
    });
    return tallyPartnerAxios.post(`channel/partner/emailAndMobileToSendOtp?type=${type}&value=${value}`, {}).then(
        (res) =>
            dispatch({
                type: C_P_SEND_OPT_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: C_P_SEND_OPT_FAILURE,
                payload: err,
            })
    );
};

const submitOTP = (key, otp) => (dispatch) => {
    dispatch({
        type: C_P_SUBMIT_OPT,
    });
    return tallyPartnerAxios.post(`channel/partner/verifyOtpCodeOfEmailAndMobile?uuId=${key}&otpCode=${otp}`, {}).then(
        (res) =>
            dispatch({
                type: C_P_SUBMIT_OPT_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: C_P_SUBMIT_OPT_FAILURE,
                payload: err,
            })
    );
};

const getReferalList = (type) => (dispatch) => {
    dispatch({
        type: GET_REFERALS_LIST,
    });
    return tallyPartnerAxios.get(`channel/partner/getReferalUsersUnderVerification?referalCodetype=${type}`).then(
        (res) =>
            dispatch({
                type: GET_REFERALS_LIST_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: GET_REFERALS_LIST_FAILURE,
                payload: err,
            })
    );
};

const getReferalListByType = (type, code) => (dispatch) => {
    dispatch({
        type: GET_REFERALS_LIST,
    });
    return tallyPartnerAxios.get(`channel/partner/getReferalsListBasedOnType?type=${type}&&code=${code}`).then(
        (res) =>
            dispatch({
                type: GET_REFERALS_LIST_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: GET_REFERALS_LIST_FAILURE,
                payload: err,
            })
    );
};

const getInvoiceList = (type, role, partnerCode) => (dispatch) => {
    dispatch({
        type: GET_C_P_INVOICE_LIST,
    });
    return tallyPartnerAxios.get(role === "ADMIN" ? `channel/partner/getAllChannelPartnerInvocieListBasedOnType?type=${type}` : `channel/partner/getChannelPartnerUserInvocieListBasedOnTypeAndCode?type=${type}&PartnerCode=${partnerCode}`).then(
        (res) =>
            dispatch({
                type: GET_C_P_INVOICE_LIST_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: GET_C_P_INVOICE_LIST_FAILURE,
                payload: err,
            })
    );
};
const getRefListForInv = (partnerCode) => (dispatch) => {
    dispatch({
        type: GET_REF_LIST_FOR_INVOICE_SAVE,
    });
    return tallyPartnerAxios.get(`channel/partner/getAddInvocieReferalListByPartnerCode?partnerCode=${partnerCode}`).then(
        (res) =>
            dispatch({
                type: GET_REF_LIST_FOR_INVOICE_SAVE_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: GET_REF_LIST_FOR_INVOICE_SAVE_FAILURE,
                payload: err,
            })
    );
};

const saveCPInvoice = (formData) => (dispatch) => {
    dispatch({
        type: SAVE_C_P_INVOICE,
    });
    return tallyPartnerAxios
        .post(`channel/partner/saveInvocieForApprovedReferals`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(
            (res) =>
                dispatch({
                    type: SAVE_C_P_INVOICE_SUCCESS,
                    payload: res.data,
                }),
            (err) =>
                dispatch({
                    type: SAVE_C_P_INVOICE_FAILURE,
                    payload: err,
                })
        );
};

const updateReferalStatusByAdmin = (data) => (dispatch) => {
    dispatch({
        type: UPDATE_REFERAL_STATUS,
    });
    return tallyPartnerAxios.post(`channel/partner/saveChannelPartnerReferals`, data).then(
        (res) =>
            dispatch({
                type: UPDATE_REFERAL_STATUS_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: UPDATE_REFERAL_STATUS_FAILURE,
                payload: err,
            })
    );
};

const updateReferalStatusByAdminByType = (id, status, remarks, modifiedBy) => (dispatch) => {
    dispatch({
        type: UPDATE_REFERAL_STATUS,
    });
    return tallyPartnerAxios.put(`channel/partner/updateStatusOfChannelPartnerReferal?id=${id}&status=${status}&remarks=${remarks}&modifiedBy=${modifiedBy}`, {}).then(
        (res) =>
            dispatch({
                type: UPDATE_REFERAL_STATUS_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: UPDATE_REFERAL_STATUS_FAILURE,
                payload: err,
            })
    );
};

const updateInvoiceStatus = (status, invId, modifiedBy, remarks) => (dispatch) => {
    dispatch({
        type: UPDATE_INVOICE_STATUS,
    });
    return tallyPartnerAxios.put(`channel/partner/updateChannelPartnerInvocieStatus?status=${status}&invId=${invId}&modifiedBy=${modifiedBy}&remarks=${remarks}`, {}).then(
        (res) =>
            dispatch({
                type: UPDATE_INVOICE_STATUS_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: UPDATE_INVOICE_STATUS_FAILURE,
                payload: err,
            })
    );
};

const updateInvPaymentStatus = (status, invId, modifiedBy, refNumber) => (dispatch) => {
    dispatch({
        type: UPDATE_INV_PAYMENT_STATUS,
    });
    return tallyPartnerAxios.put(`channel/partner/updateChannelPartnerInvoicePaymentDetails?paymentStatus=${status}&invId=${invId}&modifiedBy=${modifiedBy}&refNumber=${refNumber}`, {}).then(
        (res) =>
            dispatch({
                type: UPDATE_INV_PAYMENT_STATUS_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: UPDATE_INV_PAYMENT_STATUS_FAILURE,
                payload: err,
            })
    );
};


const getInvReflistByInvId = (invId) => (dispatch) => {
    dispatch({
        type: GET_INV_REF_LIST_BY_INV_ID,
    });
    return tallyPartnerAxios.get(`channel/partner/getAddedInvocieReferalListByInvId?invId=${invId}`).then(
        (res) =>
            dispatch({
                type: GET_INV_REF_LIST_BY_INV_ID_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: GET_INV_REF_LIST_BY_INV_ID_FAILURE,
                payload: err,
            })
    );
};

const saveCPFeedback = (data) => (dispatch) => {
    dispatch({
        type: SAVE_C_P_FEEDBACK,
    });
    return tallyPartnerAxios.post(`channel/partner/saveAndUpdateChannelPartnerFeedBack`, data).then(
        (res) =>
            dispatch({
                type: SAVE_C_P_FEEDBACK_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: SAVE_C_P_FEEDBACK_FAILURE,
                payload: err,
            })
    );
};


const getFeedbackList = (type) => (dispatch) => {
    dispatch({
        type: C_P_FEEDBACK_LIST,
    });
    return tallyPartnerAxios.get(`channel/partner/getChannelPartnerFeedbackList?type=${type}`).then(
        (res) =>
            dispatch({
                type: C_P_FEEDBACK_LIST_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: C_P_FEEDBACK_LIST_FAILURE,
                payload: err,
            })
    );
};

const getSyncPendingList = (type) => (dispatch) => {
    dispatch({
        type: GET_REFERALS_LIST,
    });
    return tallyPartnerAxios.get(`channel/partner/getChannelPartnerSyncPendingList?partnerCode=UVVT172305`).then(
        (res) =>
            dispatch({
                type: GET_REFERALS_LIST_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: GET_REFERALS_LIST_FAILURE,
                payload: err,
            })
    );
};
// 
const getBankDetailsByPartnerCode = (partnerCode) => (dispatch) => {
    dispatch({
        type: GET_BANK_DETAILS,
    });
    return tallyPartnerAxios.get(`channel/partner/getChannelPartnerBankingDetailsByCode?partnerCode=${partnerCode}`).then(
        (res) =>
            dispatch({
                type: GET_BANK_DETAILS_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: GET_BANK_DETAILS_FAILURE,
                payload: err,
            })
    );
};

const saveBankDetails = (data) => (dispatch) => {
    dispatch({
        type: SAVE_BANK_DETAILS,
    });
    return tallyPartnerAxios.post(`channel/partner/saveChannelPartnerBankingDetails`, data).then(
        (res) =>
            dispatch({
                type: SAVE_BANK_DETAILS_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: SAVE_BANK_DETAILS_FAILURE,
                payload: err,
            })
    );
};
const updateUserPassword = (id, password) => (dispatch) => {
    dispatch({
        type: SUBMIT_RESET_PASSWORD_DATA,
    });
    return tallyPartnerAxios.put(`channel/partner/updateForgotPassword?id=${id}&newPassword=${password}&modifiedBy=${"User"}`, {}).then(
        (res) =>
            dispatch({
                type: SUBMIT_RESET_PASSWORD_DATA_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: SUBMIT_RESET_PASSWORD_DATA_FAILURE,
                payload: err,
            })
    );
};
const getReferralLink = (id) => (dispatch) => {
    dispatch({
        type: GET_REFERRAL_LINK,
    });
    return tallyPartnerAxios.get(`channel/partner/getShortLinkByChennelPartnerId?chId=${id}`, {}).then(
        (res) =>
            dispatch({
                type: GET_REFERRAL_LINK_SUCCESS,
                payload: res.data,
            }),
        (err) =>
            dispatch({
                type: GET_REFERRAL_LINK_FAILURE,
                payload: err,
            })
    );
};

export {
    createChannelPartner,
    partnerLogin,
    getChannelPartnerInfo,
    getChannelPartnerList,
    changeChannelPartnerStatus,
    submitOTP,
    sendOTP,
    getReferalList,
    getInvoiceList,
    saveCPInvoice,
    updateInvoiceStatus,
    updateReferalStatusByAdmin,
    getReferalListByType,
    updateReferalStatusByAdminByType,
    getRefListForInv,
    updateInvPaymentStatus,
    getInvReflistByInvId,
    saveCPFeedback,
    getFeedbackList,
    getSyncPendingList,
    getBankDetailsByPartnerCode,
    saveBankDetails,
    updateUserPassword,
    getReferralLink
};
