import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import "./scss/style.scss";
import ResetPasswordVerification from "./views/pages/reset-password-verification/ResetPasswordVerification";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const ForgetPassword = React.lazy(() =>
  import("./views/pages/forget-password/ForgetPassword")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
    };
  }

  render() {
    return (
      <>
        {/* <CacheBuster/> */}
        {/* <Disclaimar/> */}
        {/* <LiveChat /> */}
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />

              <Route
                exact
                path="/forgot-password"
                name="Forget Password"
                render={(props) => <ForgetPassword {...props} />}
              />
              <Route
                exact
                path="/verify_forgot_password"
                name="Reset Password Verification"
                render={(props) => <ResetPasswordVerification {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              {
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
              }
            </Switch>
          </React.Suspense>
        </HashRouter>
      </>
    );
  }
}

export default App;
