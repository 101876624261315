import {
    GET_USER_COMPANIES_IN_LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    STORE_TANSACTION_DATA,
    GET_BOOK_START_AND_END_DATE_SUCCESS,
    GET_JWT_TOKEN_SUCCESS,
    NOTIFICATION_SUBSCRIPTION_DATA,
    SET_USER_LOGIN_STATUS,
    STOCK_SUMMARY_DATA,
    LOGIN_CHANNEL_PARTNER,
    LOGIN_CHANNEL_PARTNER_SUCCESS,
    SET_LOGIN_CHANNEL_PARTNER_DATA
} from "src/actions/actionTypes";

const initialState = {
    loginUserData: [],
    transactIonData: {},
    bookStartDateEndDate: {},
    inviteeStatus: {},
    jwtToken: "",
    notificationSubScriptionData: null,
    userLoginStatus: false,
    stockSummaryData: {},
    currentUserData: null
};

export default function userReduser(state = initialState, action) {
    switch (action.type) {
        case GET_USER_COMPANIES_IN_LOGIN_SUCCESS: {
            return { ...state, loginUserData: action.payload };
        } case LOGOUT_SUCCESS: {
            return { ...state, loginUserData: {} };
        } case STORE_TANSACTION_DATA: {
            return { ...state, transactIonData: action.payload };
        }
        case GET_BOOK_START_AND_END_DATE_SUCCESS: {
            return { ...state, bookStartDateEndDate: action.payload }
        }
        // case USER_VERIFICATION: {
        //     return { ...state, inviteeStatus: action.payload };
        // }
        // case USER_VERIFICATION_SUCCESS: {
        //     return { ...state, inviteeStatus: action.payload };
        // }
        case GET_JWT_TOKEN_SUCCESS: {
            return { ...state, jwtToken: action.payload };
        }
        case NOTIFICATION_SUBSCRIPTION_DATA: {
            return { ...state, notificationSubScriptionData: action.payload };
        }
        case SET_USER_LOGIN_STATUS: {
            return { ...state, userLoginStatus: action.payload };
        }
        case STOCK_SUMMARY_DATA: {
            return { ...state, stockSummaryData: action.payload };
        }
        case SET_LOGIN_CHANNEL_PARTNER_DATA: {
            return { ...state, currentUserData: action.payload };
        }
        default:
            return state;
    }
}

