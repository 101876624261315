import { combineReducers } from "redux";
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import miscReducer from "./miscReducer";
import globalFilterReducer from "./globalFilterReducer"
import userReduser from './userReducer'
import partnerReducer from "./partnerReducer";
import registration from "./registrationReducer";


const initialState = {
  sidebarShow: "responsive",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userReduser"]
}

const appReducer = combineReducers({
  changeState,
  miscReducer,
  globalFilterReducer,
  userReduser,
  registration,
  partnerReducer
});
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_CHANNEL_PARTNER') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
export default persistReducer(persistConfig, rootReducer);

