import React, { useState, useEffect } from "react";
import Logofull from "src/assets/icons/logo-partner.png";
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CInput,
    CInputGroup,
    CCardGroup,
    CInputGroupPrepend,
    CRow
} from "@coreui/react";
import { Formik } from "formik"
import * as yup from "yup"
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "src/components/toast-message/ToastMessage";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { submitResetPassword, verifyRestPassword } from 'src/actions/registration';
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/core";
import { getUserName } from "src/util/miscUtil";
import { updateUserPassword } from "src/actions/partner";



const ResetPasswordVerification = () => {
    const location = useLocation();
    const [uid, setUid] = useState('');
    const [token, setToken] = useState('');
    const [responseMsg, setResponseMsg] = useState('')
    const verifyRestPasswordData = useSelector(state => state?.registration?.verifyRestPassword)
    const submitResetPassResp = useSelector(state => state?.registration?.submitResetPassResp)
    const [showContent, setShowContent] = useState("")
    useEffect(() => {
        let params = new URLSearchParams(location.search);
        setUid(params.get('uid'))
        setToken(params.get('token'))

    }, [location]);

    const dispatch = useDispatch()
    const [showToast, setshowToast] = useState(false);
    const [ToastSeverity, setToastSeverity] = useState('')
    const [hideResetBtn, setHideResetBtn] = useState(false);

    const [initialValuesReset, setInitialValuesReset] = useState({
        password: "",
        confirm_password: ""
    });

    const history = useHistory()

    useEffect(() => {
        if (uid && token) {
            const data = {
                "userId": uid,
                "token": token
            }
            dispatch(verifyRestPassword(data))
        }
    }, [uid, token])

    const [loading, setloading] = useState(true)

    const override = css`
       display: block;
       margin: 0 auto;
       border-color: red;
     `;
    useEffect(() => {
        if (verifyRestPasswordData) {
            setloading(false)
            if (verifyRestPasswordData.status) {
                setShowContent("reset-password")
            } else {
                setShowContent("link-expired")
                setmailContent(verifyRestPasswordData?.response)
            }
        }

    }, [verifyRestPasswordData])


    const updatePassword = (v) => {
        const data = {
            "userId": atob(uid),
            "token": token,
            "password": v.password,
            "confirmPassword": v.confirm_password
        }
        // dispatch(submitResetPassword(data))
        dispatch(updateUserPassword(data.userId, data.password))
        setloading(true)
    }

    const validationSchema = yup.object().shape({
        password: yup
            .string()
            .min(4)
            .max(30, 'you can not enter more than 30 charcters')
            .required(),
        confirm_password: yup
            .string()
            .required("confirm password is required")
            .oneOf(
                [yup.ref('password'), null],
                'Passwords must match',
            ),
    })
    const [mailContent, setmailContent] = useState("")
    useEffect(() => {
        if (submitResetPassResp) {
            setloading(false)
            if (submitResetPassResp === "Success") {
                setShowContent('password-reset-successful')
                setmailContent("Password Reset Successfully")
            }
        }
    }, [submitResetPassResp])

    const onClickOnLogin = () => {
        if (getUserName()) {
            setshowToast(false)
            setToastSeverity("error")
            setResponseMsg("Multiple users not allowed! Please logout and try again")
            setTimeout(() => {
                setshowToast(true)
            }, 0);
        } else {
            history.push("/login")
        }
    }
    const navigatetoforgetPassword = () => {
        history.push("/forgot-password")
    }
    const [visibilityCPassWord, setVisibilityCPassWord] = useState(false)
    const [visibilityPassWord, setVisibilityPassWord] = useState(false)
    return (
        <>
            <div className="">
                <div className="c-app c-default-layout flex-row align-items-center mdb-shadow">
                    <CContainer>
                        <CRow className="justify-content-center">
                            <CCol xl="6" lg="6" md="12" sm="12">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                        <div className="p-2 mb-2">
                                            <img src={Logofull} className="c-sidebar-brand-full mobile-logo-width img-fluid" />{" "}
                                        </div>
                                    </div>
                                </div>
                                <CCard className="border-0">
                                    <CCardBody className="p-0">

                                        <div className="text-center p-5 inside-border-radius">
                                            {!loading &&
                                                <div>
                                                    {showContent == "reset-password" && <CCard className="border-0">
                                                        <CCardBody className="">
                                                            <div className="text-center pb-1 inside-border-radius">
                                                                {/* <h1 className="reset-icon-size mb-2"><i className="fas fa-key" aria-hidden="true"></i></h1> */}
                                                                <h2 className="bold-label">Reset Your Password</h2>
                                                                <h6 className="bold-label">Enter Your New Password Here.</h6>
                                                            </div>
                                                            <CCardGroup>
                                                                <CCard className="mx-4 border-0">
                                                                    {!hideResetBtn ? (<CCardBody className="pl-0 pr-4 pb-4 pt-4 mobile-padding">
                                                                        <Formik
                                                                            enableReinitialize={true}
                                                                            initialValues={initialValuesReset}
                                                                            validationSchema={validationSchema}
                                                                            onSubmit={((values) => {
                                                                                updatePassword(values)
                                                                            })}

                                                                        >
                                                                            {({ handleChange, handleSubmit, values, errors, touched }) => (
                                                                                <form onSubmit={handleSubmit}>

                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <CInputGroup className="mb-3">
                                                                                                <CInputGroupPrepend>
                                                                                                </CInputGroupPrepend>
                                                                                                <CInput
                                                                                                    value={values.password}
                                                                                                    type={visibilityPassWord ? "text" : "password"}
                                                                                                    name="password"
                                                                                                    placeholder="Password" className="light-login-bg shadow-sm rounded-pil border-r-b form-control-lg border-0 custom-input"
                                                                                                    onChange={handleChange}
                                                                                                />
                                                                                                {/* <i className="fas fa-key inside-icon"></i> */}
                                                                                                {!visibilityPassWord && <i className="fa fa-eye inside-icon c-pointer" aria-hidden="true" onClick={() => setVisibilityPassWord(!visibilityPassWord)}></i>}
                                                                                                {visibilityPassWord && <i className="fa fa-eye-slash inside-icon c-pointer" aria-hidden="true" onClick={() => setVisibilityPassWord(!visibilityPassWord)}></i>}
                                                                                            </CInputGroup>
                                                                                            {errors.password && touched.password ? (<div className="mb-1 sign-error-msgs text-danger">{errors.password}</div>) : null}
                                                                                        </div>
                                                                                        <div className="col-md-12">
                                                                                            <CInputGroup className="mb-4">
                                                                                                <CInputGroupPrepend>
                                                                                                </CInputGroupPrepend>
                                                                                                <CInput
                                                                                                    value={values.confirm_password}
                                                                                                    type={visibilityCPassWord ? "text" : "password"}
                                                                                                    name="confirm_password"
                                                                                                    placeholder="Confirm Password" className="light-login-bg shadow-sm rounded-pil border-r-b form-control-lg border-0 custom-input"
                                                                                                    autoComplete="new-password"
                                                                                                    onChange={handleChange}

                                                                                                />
                                                                                                {/* <i className="fas fa-key inside-icon"></i> */}
                                                                                                {!visibilityCPassWord && <i className="fa fa-eye inside-icon c-pointer" aria-hidden="true" onClick={() => setVisibilityCPassWord(!visibilityCPassWord)}></i>}
                                                                                                {visibilityCPassWord && <i className="fa fa-eye-slash inside-icon c-pointer" aria-hidden="true" onClick={() => setVisibilityCPassWord(!visibilityCPassWord)}></i>}

                                                                                            </CInputGroup>
                                                                                            {errors.confirm_password && touched.confirm_password ? (<div className="mb-1 sign-error-msgs text-danger">{errors.confirm_password}</div>) : null}
                                                                                        </div>

                                                                                        <div className="col-md-12">
                                                                                            <div className="text-center bg-white mb-0">
                                                                                            </div>
                                                                                            <CButton className="btn btn-primary btn-lg  btn-shadow btn-block" type="submit">
                                                                                                Update Password
                                                                                            </CButton>
                                                                                        </div>
                                                                                    </div>
                                                                                </form>
                                                                            )}
                                                                        </Formik>


                                                                    </CCardBody>) :


                                                                        (<CCardBody className="pl-0 pr-4 pb-4 pt-4 mobile-padding">
                                                                            <div className="text-center bg-white mb-0">
                                                                                <h3>Your password has been changed successfully</h3>
                                                                                <h6 className="text-center mb-0">Click <Link to="/login" className="text-primary bold-label">here</Link> to login</h6>
                                                                            </div>
                                                                        </CCardBody>)}

                                                                </CCard>

                                                            </CCardGroup>
                                                        </CCardBody>
                                                    </CCard>}

                                                    {showContent == "link-expired" &&
                                                        <div>
                                                            <span className="verify-warning-icon-view">
                                                                <i className="fas fa-exclamation" aria-hidden="true"></i>
                                                            </span>
                                                            <h5 className="bold-label mb-4 mt-4">{mailContent}</h5>
                                                            <hr className="hr-light-border mt-3 mb-2 w-25"></hr>
                                                            <div className="pl-2 pr-2">
                                                                <button onClick={navigatetoforgetPassword} className="btn btn-primary btn-md btn-shadow">Resend Link</button>
                                                            </div>
                                                        </div>
                                                    }

                                                    {showContent == "password-reset-successful" && <div className="success-full">
                                                        <span className="verify-success-icon-view">
                                                            <i className="fas fa-user" aria-hidden="true"></i>
                                                        </span>
                                                        <h5 className="bold-label mb-4 mt-4">Successful</h5>
                                                        <hr className="hr-light-border mt-2 mb-2 w-25"></hr>
                                                        <div className="pl-2 pr-2">
                                                            <h6 className="bold-label mb-4 line-height-custom">{mailContent}</h6>
                                                            {/* {!emailForLogIn && <button className="btn btn-primary btn-lg status-font btn-shadow" onClick={() => onClickGetStarted()}><i class="fas fa-hand-point-up"></i> Get Started</button>} */}
                                                            {<button className="btn btn-primary btn-lg status-font btn-shadow" onClick={() => onClickOnLogin()}><i class="fas fa-hand-point-up"></i> Login</button>}
                                                        </div>
                                                    </div>}
                                                </div>
                                            }

                                            {loading && <div className="card-body p-3 text-center">
                                                <div className="mt-5 mb-5 text-center">
                                                    <ScaleLoader
                                                        css={override}
                                                        // size={60 , 5}
                                                        height={50}
                                                        width={5}
                                                        margin={5}
                                                        color={"#f58b3d"}
                                                        loading={loading}
                                                    />
                                                </div>
                                            </div>}
                                        </div>

                                    </CCardBody>
                                </CCard>

                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </div>
            {showToast &&
                <ToastMessage
                    severity={ToastSeverity}
                    summary={ToastSeverity == "error" ? "Error" : "Success"}
                    detail={responseMsg}
                />
            }
        </>
    )
};
export default ResetPasswordVerification